import * as React from 'react';


import { HomeHeader } from '../components/home/HomeHeader';
import { Footer } from '../components/home/Footer';
import { NewsDetails } from '../components/news';
import { Helmet } from 'react-helmet';
export const News = () => {
  return(
      <div id="app">
        <Helmet>
        <title>Notizia - Amaroni Borgo</title>
        <meta
          name="description"
          content="Leggi le ultime notizie su Amaroni Borgo, gli eventi, la cultura e il turismo della regione."
        />
        <meta
          name="keywords"
          content="Amaroni, Notizie, eventos, cultura, turismo, Amaroni Borgo"
        />
      </Helmet>

        <HomeHeader logged={false}/>
        <NewsDetails />
        <Footer/>
      </div>
  );
}