import styled from 'styled-components';

export const Content = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;

    .container {
        display: flex;
        flex-direction: column;

        @media (max-width: 760px) {
<<<<<<< HEAD
            padding: 15px;
=======
            padding: 10px;
>>>>>>> c415e8aca664a869c148a9d52dfce3b6b3bf6b24
        }
    }
`;