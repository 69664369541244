import styled from 'styled-components';

export const Content = styled.div`
	.container {
		margin-top: 30px;

		p {
			text-align: justify;
		}

		.gallery-content {
			height: fit-content;
		}
	}
`;