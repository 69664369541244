import styled from 'styled-components';

export const Content =  styled.section`
    flex: 1; 
    width:100%;
    display: flex;
    flex-direction: column;  
    align-items: flex-start;
    justify-content: flex-start;
`;


