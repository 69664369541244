import * as React from 'react';
import { HomeHeader } from '../components/home/HomeHeader';
import { GalleryContent } from '../components/gallery';
import { Footer } from '../components/home/Footer';
import { Helmet } from 'react-helmet';

export const Gallery = () => {
    
    const [en, setEn] = React.useState(false);

    const handleLanguage = (current: boolean) => {
        if (current) {
          setEn(false);
        } else {
          setEn(true);
        }
    }
    return(
        <div id="app">
          <Helmet>
            <title>Galleria - Amaroni Borgo</title>
            <meta
              name="description"
              content="Esplora la nostra galleria e immergiti nella bellezza di Amaroni Borgo. Scopri i paesaggi incantevoli, l'architettura storica, la cultura vibrante e gli eventi locali attraverso le immagini."
            />
            <meta
              name="keywords"
              content="Amaroni, Galleria, paesaggi, architettura, cultura, eventi, turismo, Amaroni Borgo"
            />
          </Helmet>

          <HomeHeader logged={false} />
          <GalleryContent />
          <Footer/>
        </div>
    );
}